<template>
  <div>
    <OrderOverviewModal
      v-if="showOrderOverviewModal && customer"
      :order="order"
      :customer="customer"
      :pending="pending"
      :create-document="true"
      button-text="Antrag erstellen"
      @save="save"
      @close="showOrderOverviewModal = false"
    />
    <ca-header
      heading="Angebot annehmen"
      :loading="loading"
      :column-layout="columnLayout"
      show-column-layout
      @columnLayout="columnLayout = $event"
    >
      <template #customLoading>
        <ca-alert
          variant="info"
          spin-icon
          rounded
          icon="circle-notch"
          message="Lade Antragsdaten..."
        />
      </template>
    </ca-header>
    <form>
      <div class="container-fluid px-4 pt-4">
        <div
          v-if="!loading"
          class="row"
        >
          <div
            class="col-12"
            :class="{'col-xl-8 col-xxl-6': !columnLayout}"
          >
            <div class="row">
              <div
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <HSSOtherForm
                  :customer="customer"
                  class="mb-3"
                  :order="order"
                  required
                  is-angebot-edit
                  @hardknockout-change="$set(hardknockouts, 'otherForm', $event)"
                />
              </div>
              <div
                class="col-12"
                :class="{ 'col-lg-6': columnLayout }"
              >
                <HssSepaForm
                  :order="order"
                  required
                />
              </div>
            </div>

            <HSSResultForm
              :customer="customer"
              class="mb-3"
              :order="order"
              :result="order.calculatedData"
              :client="client"
              required
            />
            <div
              class="form-row justify-content-end align-items-center mt-3"
            >
              <p class="mr-2 mb-0">
                *Pflichtfeld
              </p>

              <button
                type="button"
                class="btn text-primary"
                @click="abort"
              >
                Abbrechen
              </button>
              <button
                type="button"
                class="btn btn-primary"
                :disabled="isHardknockout || pending"
                @click="validateData"
              >
                Antrag erstellen
                <i
                  v-if="pending"
                  class="fas fa-circle-notch fa-spin"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import CaHeader from '@/components/Layout/Header.vue'
import feathers from '@/api'
import OrderOverviewModal from '@/components/Modals/OrderOverviewModal.vue'
import HSSOtherForm from '@/components/Forms/Order/HSSOtherForm.vue'
import HssSepaForm from '@/components/Forms/Order/PaymentInformationForm.vue'
import HSSResultForm from '@/components/Forms/Order/HSSResultForm.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: { CaHeader, OrderOverviewModal, HSSOtherForm, HssSepaForm, HSSResultForm },
  data () {
    return {
      loading: false,
      order: null,
      customer: null,
      error: null,
      hardknockouts: {},
      pending: false,
      showValidationError: false,
      showOrderOverviewModal: false,
      client: null,
      columnLayout: true
    }
  },
  computed: {
    isHardknockout () {
      return Object.values(this.hardknockouts).includes(true)
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.order = await this.$store.dispatch('hs-order/get', this.$route.params.orderId)
        const motorstaerkeMax = (await feathers.service('hsdata-fz-motorstaerke').find({
          query: {
            von: { $lte: this.order.carData.motorstaerke },
            bis: { $gte: this.order.carData.motorstaerke }
          }
        })).data[0]
        this.order.motorstaerkeMax = motorstaerkeMax.bis
        this.customer = this.order.customer
        this.client = (await feathers.service('clients').find()).data[0]
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async abort () {
      this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
    },
    async validateData () {
      this.showValidationError = !await this.$validator.validateAll() || !this.order.product
      if (this.showValidationError) return
      this.showOrderOverviewModal = true
    },
    async save () {
      this.pending = true
      try {
        await feathers.service('hs-order-process-angebot-annehmen').create(this.order)
      } catch (e) {
        console.error(e)
      } finally {
        this.pending = false
        this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
      }
    }
  }
}
</script>
