<template>
  <Modal
    title="Übersicht"
    size="lg"
    max-height
    @close="$emit('close')"
  >
    <template #body>
      <HSSOrderData
        class="h-100 overflow-auto"
        :customer="customer"
        :order="order"
        :result="result"
      />
    </template>
    <template #footer>
      <form>
        <template v-if="createDocument">
          <div>
            <p v-if="documentPending">
              <i
                class="fas fa-circle-notch fa-spin"
              />
              Dokumente werden erstellt.
            </p>
            <template v-else>
              <SignedDownloadLink
                :file="pdf"
                class="d-block"
              >
                <template #default="{link}">
                  <a :href="link">
                    <i class="fas fa-file-pdf" />
                    Antragsvorschau herunterladen</a>
                </template>
              </Signeddownloadlink>
              <SignedDownloadLink
                :file="beratungsprotokoll"
                class="d-block"
              >
                <template #default="{link}">
                  <a :href="link">
                    <i class="fas fa-file-pdf" />
                    Beratungsprotokoll herunterladen</a>
                </template>
              </Signeddownloadlink>
            </template>
          </div>
          <BasicCheckbox
            v-model="information_confirmation"
            required
            name="Vollständigkeit und Richtigkeit der Angaben"
            label="Der Kunde hat das Antragsdokument erhalten und bestätigt die Vollständigkeit und Richtigkeit der Angaben. Auf die „Erklärungen und wichtigen Hinweise“, insbesondere der Regelungen zur vorvertraglichen Anzeigepflicht und zum Widerrufsrecht, wurde der Kunde von mir hingewiesen."
          />
          <BasicCheckbox
            id="consulting"
            v-model="order.beratungprotokoll"
            name="Beratungsprotokoll"
            required
            label="Der Kunde wurde durch mich/uns ausführlich beraten. Ein Beratungsprotokoll wurde geführt."
            class="mb-4"
          />
          <!-- <BasicCheckbox
            id="newsletter"
            v-model="order.newsletter"
            label="Der Kunde wünscht zukünftig über Neuigkeiten informiert zu werden. (z. B. per E-Mail)"
            class="mb-4"
          /> -->
          <BasicCheckbox
            v-model="order_confirmation"
            required
            name="Kostenpflichtig beantragen"
            label="Der Kunde möchte den Versicherungsschutz jetzt kostenpflichtig beantragen."
          />
        </template>
        <Ueberpruefung
          v-if="errorMessage"
          :message="errorMessage"
          class="mb-2"
        />
        <div class="d-flex flex-row-reverse bd-highlight pb-2">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="pending || documentPending"
            @click="sendSave"
          >
            {{ buttonText }}
            <i
              v-if="pending"
              class="fas fa-circle-notch fa-spin"
            />
          </button>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import BasicCheckbox from '../BaseComponents/BasicCheckbox.vue'
import HSSOrderData from '../HSSOrderData.vue'
import feathers from '@/api'
import SignedDownloadLink from '../SignedDownloadLink.vue'
import hsOrderTypes from '@/resources/enums/hsOrderTypes'
import Ueberpruefung from '../Forms/Order/Ueberpruefung.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicCheckbox,
    HSSOrderData,
    SignedDownloadLink,
    Ueberpruefung
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: [Object, null],
      required: true
    },
    result: {
      type: Object,
      default: null
    },
    pending: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'Vorgang abschließen'
    },
    createDocument: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      information_confirmation: false,
      order_confirmation: false,
      pdf: null,
      beratungsprotokoll: null,
      documentPending: false,
      hsOrderTypes
    }
  },
  async created () {
    if (this.createDocument) {
      this.documentPending = true
      this.pdf = await feathers.service('zwischenantrag').create({
        ...this.order,
        customer: this.customer
      })
      this.beratungsprotokoll = await feathers.service('beratungsprotokoll').create({
        ...this.order,
        customer: this.customer
      })
      this.documentPending = false
    }
  },
  methods: {
    async sendSave () {
      if (this.createDocument) {
        if (this.documentPending) return
        if (!this.order.fileIds) this.$set(this.order, 'fileIds', [])
        this.order.fileIds.push(this.pdf._id)
      }
      if (!await this.$validator.validateAll()) return
      this.$emit('save')
    }
  }
}
</script>
